import { useMutation } from '@tanstack/react-query';
import client from '../client';
import { message } from 'antd';
import { UseApproveApplicant } from '../../types/api/applicant';

type Options = (data: any) => void

interface IApproveApplicant {
  sectionId: string
  applicantIds: string[]
  note: string | undefined
}

const useApproveApplicant: UseApproveApplicant = (options: Options) => {
  const mutation = useMutation({
    mutationKey: ['api/applicant/course/status/approve'],
    mutationFn: async (params: IApproveApplicant) => {
      if (params.sectionId !== undefined) {
        return await client.post(
          `api/applicant/course/${params.sectionId}/status/approve`,
          {
            applicantIds: params.applicantIds,
            note: params.note
          }
        );
      }
    },
    onError: ({
      response: {
        data: { message: error }
      }
    }) => message.error(error, 2),
    ...options
  }
  );
  return mutation;
};

export default useApproveApplicant;
