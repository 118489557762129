
import React, { useState } from 'react';
import { Input, Space } from 'antd';
import styled from 'styled-components';
import { AsnButton } from '../../../../../../../Forms/Button';
import { AsnModal } from '../../../../../../../Forms/Modal';
import { IChangeEmailModalProps } from '../../../../../../../../types/applicant';
import { AsnTable } from '../../../../../../../Forms/Table';
import { IWrongEmailProps } from '../../../../../../../Project/SubActivity/SubActivitySections/ApplicantFullInfo/Status';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/edit.svg';
import useChangeEmailApplicant from '../../../../../../../../api/Applicants/useChangeApplicantEmail';
import { Void } from '../../../../../../../../types/global';
import { useQueryClient } from '@tanstack/react-query';

interface IChangeData {
  id: string
  email: string
}
interface IResponceData {
  id: string
  fullName: string
  phone: string
  email: string
  course: string
}

const AntModal = styled(AsnModal)`
  .ant-modal-content {
    padding: 2rem;
    .ant-modal-header {
      padding-bottom: 0;

      .ant-modal-title {
        color: var(--dark-2);
        padding-bottom: 2rem;
      }
    }

    .ant-modal-body {
      .name {
        display: flex;
        font-size: var(--base-font-size);
        color: var(--dark-3);
        padding-top: 8px;

        flex-wrap: wrap;
        max-height: 200px;
        overflow: auto;
      }

      .add-note {
        font-size: var(--font-size-semismall);
        color: var(--dark-2);
      }

      .text-area {
        textarea {
          min-height: 8rem;
          resize: initial;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        height: 5rem;
        align-items: self-end;

        > .ant-space-item {
          display: flex;
          justify-content: center;
          width: 10rem;
        }

        button {
          width: 130px;
        }
      }
    }
  }
`;

const AntTable = styled(AsnTable)`
  .ant-table-tbody > tr > td {
    overflow-wrap: anywhere;
  }
`;

const ChangeEmailModal: React.FC<IChangeEmailModalProps> = ({
  data,
  setData,
  onCancel
}) => {
  const [changData, SetChangeData] = useState<IChangeData[]>([]);
  const [editingId, setEditingID] = useState<string | null>(null);
  const { mutate: changeEmail } = useChangeEmailApplicant();
  const queryClient = useQueryClient();

  const onSendEmail: Void = () => {
    changeEmail(changData, {
      onSuccess: (response: string | IResponceData[]) => {
        if (typeof response === 'string') {
          SetChangeData([]);
          setData([]);
        } else {
          SetChangeData([]);
          setData(response);
        }
        void queryClient.invalidateQueries([
          'api/applicant/:id/course/:sectionDataId/history'
        ]);
        void queryClient.invalidateQueries([
          '/api/applicant/course/:sectionDataId'
        ]);
      }
    });
  };

  const startEditing = (id: string): void => {
    if (editingId === null || id !== editingId) {
      setEditingID(id);
    } else {
      setEditingID(null);
    }
  };

  const stopEditing = (id: string, email: string): void => {
    const newObject = {
      id,
      email
    };

    const indexToUpdate = changData.findIndex((item) => item.id === id);

    if (indexToUpdate !== -1) {
      const newData = [...changData];
      newData[indexToUpdate] = newObject;
      SetChangeData(newData);
    } else {
      SetChangeData((prevData) => [...prevData, newObject]);
    }

    setEditingID(null);
  };
  const isEditing = (id: string): boolean => id === editingId;

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ): void => {
    const updatedData = [...data];
    const targetItem = updatedData.find((item) => item.id === key);
    if (targetItem != null) {
      targetItem.email = e.target.value;
      setData(updatedData);
    }
  };
  const ChangeEmailsColumns = [
    {
      title: 'Name/Surname',
      key: 'fullName',
      dataIndex: 'fullName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: string, record: IWrongEmailProps) => {
        if (isEditing(record.id)) {
          return (
            <Input
            value={email}
            onChange={(e) => handleEmailChange(e, record.id)}
            onBlur={() => stopEditing(record.id, record.email)}
            onPressEnter={() => stopEditing(record.id, record.email)}
          />
          );
        } else {
          return <span>{email}</span>;
        }
      }
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'edit',
      render: (email: string, record: IWrongEmailProps) => (
        <span onClick={() => startEditing(record.id)}>
          <EditIcon />{' '}
        </span>
      )
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Course',
      key: 'course',
      dataIndex: 'course'
    }
  ];

  return (
    <AntModal
      title="If the Email is Non-Existing, you can Edit it"
      onCancel={onCancel}
      open={data.length !== 0}
      footer={false}
      width="auto"
      closable
      centered
    >
      <AntTable
        dataSource={data}
        columns={ChangeEmailsColumns}
        pagination={false}
      />
      <Space className="buttons">
        <Space>
          <AsnButton className="default" onClick={onCancel}>
            Cancel
          </AsnButton>
        </Space>
        <Space>
          <AsnButton onClick={() => onSendEmail()} className="primary">
            Save
          </AsnButton>
        </Space>
      </Space>
    </AntModal>
  );
};

export default ChangeEmailModal;
