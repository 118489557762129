import { useMutation } from '@tanstack/react-query';
import { Iseacrh } from '../../pages/Applicatns/applicantsTypes';

const useUploadApplicantsFiles: any = (options = {}) =>
  useMutation(async (params: { projectId: string, body: Iseacrh }) => {
    if (params.projectId !== undefined) {
      try {
        const filename = '';
        const token: string | null = JSON.parse(
          localStorage.getItem('token') as string
        );

        if (token !== null) {
          void fetch(
            `https://apimeetk.analysed.ai/api/applicant/download/list/project/${params.projectId}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify(params.body)
            }
          )
            .then(async (result) => {
              if (!result.ok) {
                throw Error(result.statusText);
              }
              return await result.blob();
            })
            .then((blob) => {
              if (blob != null) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
              }
            });
        }

        return {};
      } catch (error) {}
    }
  }, options);
export default useUploadApplicantsFiles;
