import React from 'react';
import { AsnForm } from '../../../components/Forms/Form';
import { Col, Radio } from 'antd';
import { PickerSpace } from '../applicantsStyle';
import { AsnDatePicker } from '../../../components/Forms/DatePicker';
import moment, { Moment } from 'moment';
import { FormFinish } from '../../../types/global';
import { IDataFilter } from '../applicantsTypes';

const DataFilter: React.FC<IDataFilter> = ({
  setDateRadio,
  dateRadio,
  dateRadioButton,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  setDateRadioButton
}) => {
  const onChange: (item: Moment | null) => void = (item) => {
    if (item !== null) {
      setStartTime(item.format());
    }
  };
  const onChangeEnd: (item: Moment | null) => void = (item) => {
    if (item !== null) {
      setEndTime(item.format());
    }
  };

  const disabledDateEndPicker: (current: Moment) => boolean = (
    current: Moment
  ) => {
    return current < moment(startTime);
  };

  const disabledDateStartPicker: (current: Moment) => boolean = (
    current: Moment
  ) => {
    return current > moment(endTime);
  };

  const handleRadioChange: FormFinish = (event) => {
    setDateRadio(event.target.value);
  };

  return (
    <div>
      <AsnForm.Item
        name={['date', 'start']}
        style={{
          margin: '0 0 10px'
        }}
        rules={
          dateRadioButton === '8' && dateRadio === undefined
            ? [{ required: true, message: 'Please enter start or end field' }]
            : [{ required: false }]
        }
      >
        <Radio.Group onChange={handleRadioChange}>
          <Radio value={true}>Start Date </Radio>
          <Radio value={false}>End Date </Radio>
        </Radio.Group>
      </AsnForm.Item>
      <PickerSpace size={0}>
        <Col span={22}>
          <AsnForm.Item
            name={['date', 'from']}
            label="from"
            rules={
              dateRadio !== undefined || dateRadioButton === '8'
                ? [{ required: true, message: 'Please select the Start date' }]
                : [{ required: false }]
            }
          >
            <AsnDatePicker
              format="DD/MM/YYYY"
              placeholder="01/01/23"
              onChange={onChange}
              disabledDate={disabledDateStartPicker}
              disabled={dateRadio === undefined}
            />
          </AsnForm.Item>
        </Col>
        <Col span={22}>
          <AsnForm.Item
            name={['date', 'to']}
            label="to"
            rules={
              dateRadio !== undefined || dateRadioButton === '8'
                ? [{ required: true, message: 'Please select the End date' }]
                : [{ required: false }]
            }
          >
            <AsnDatePicker
              placeholder="01/01/23"
              format="DD/MM/YYYY"
              onChange={onChangeEnd}
              disabledDate={disabledDateEndPicker}
              disabled={dateRadio === undefined}
            />
          </AsnForm.Item>
        </Col>
      </PickerSpace>
    </div>
  );
};

export default DataFilter;
