import { useMutation } from '@tanstack/react-query';
import { IRequestEmailChange } from '../../types/applicant';
import { UseChangeEmailApplicant } from '../../types/api/applicant';
import client from '../client';

const URL_UPDATE_EMAILS = '/api/applicant/update/emails';
interface IResponceData {
  id: string
  fullName: string
  phone: string
  email: string
  course: string
}
const useChangeEmailApplicant: UseChangeEmailApplicant = () => {
  const changeEmailApplicant = async (data: IRequestEmailChange[]): Promise<IResponceData | string> => {
    const response = await client.put(URL_UPDATE_EMAILS, data);
    return response.data.result;
  };

  const mutation = useMutation(changeEmailApplicant);

  return mutation;
};

export default useChangeEmailApplicant;
