import React from 'react';
import styled from 'styled-components';
import { Input, Row, Space, Typography } from 'antd';
import { SearchApplicants } from './applicantsTypes';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { Void } from '../../types/global';
import useUploadApplicantsFiles from '../../api/Applicants/useUploadApplicantsExcel';
import { useProject } from '../../hooks/useProject';
import _ from 'lodash';

const { Title } = Typography;

const CustomButton = styled.div`
  cursor: pointer;
`;

const UseSearch: React.FC<SearchApplicants> = ({
  serachData,
  result,
  setOffset,
  filters
}) => {
  const { projectId }: { projectId: string } = useProject();
  const { mutate: addFileApplicants } = useUploadApplicantsFiles();

  const onChange = (data: React.ChangeEvent<HTMLInputElement>): void => {
    if (data.target.value === '') {
      serachData(undefined);
      setOffset(0);
    }
  };

  const onPressEnter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value.trim().length > 0) {
      serachData(e.currentTarget.value.trim());
      setOffset(0);
    }
  };

  const onSaveExcel: Void = () => {
    const filtersClone = _.cloneDeep(filters);
    delete filtersClone.limit;
    delete filtersClone.offset;
    if (
      filtersClone?.date?.start === undefined &&
      filtersClone.date?.from === undefined &&
      filtersClone.date?.to === undefined
    ) {
      filtersClone.date = undefined;
    }
    if (result?.count > 0) {
      addFileApplicants({
        projectId,
        body: {
          ...filtersClone
        }
      });
    }
  };

  return (
    <>
      <Space
        size={[15, 0]}
        style={{ padding: '30px 18px 4px', display: 'flex' }}
      >
        <Title level={4} style={{ color: 'var(--dark-border-ultramarine)' }}>
          Applicants
        </Title>
        <Input
          style={{ width: 300 }}
          onChange={onChange}
          onPressEnter={onPressEnter}
          placeholder="Search..."
        />
        <Row
          style={{
            position: 'absolute',
            right: '25px',
            top: '111px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <CustomButton>
            <Download onClick={onSaveExcel} />
          </CustomButton>
          <span style={{ border: '1px solid #D9D9D9', padding: ' 4px 20px' }}>
            Total: {result?.count}
          </span>
        </Row>
      </Space>
    </>
  );
};

export default UseSearch;
